@import '../../styles/utils/variables';
@import '../../styles/utils//mixins';

.textFieldAutoCompliteContainer {
  flex: 1;
  width: 100%;
  position: relative;

}
.autocompliteDropdown {
  position: absolute;
  z-index: 999;
  width: 100%;
  border-radius: 0.4rem;
  background-color: $background-color-primary;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.suggestion {
  font-size: 1.4rem !important;
  color: $text-color-primary !important;
  @include mq(xxl) {
    font-size: 1.2rem !important;
  }
}
.suggestionActive {
  background-color: #e0e0e0 !important;
}
