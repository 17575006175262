@import '../utils/variables';

.DayPicker {
  width: 100%;
  font-size: 1.6rem !important;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05),
    0px 16px 24px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  & > .DayPicker-wrapper {
    & > .DayPicker-NavBar {
      position: absolute;
      top: -1.6rem;
      right: 1rem;
      left: 1rem;
      margin-top: 2px;
      width: 100%;
      height: 1.25em;
      background-position: center;
      background-size: 60%;
      background-repeat: no-repeat;
      color: #8b9898;
      cursor: pointer;
      outline: none;
      & > .DayPicker-NavButton--prev {
        left: -1rem;
        top: 2rem;
        background-image: url('../../assets/app/goBackIcon.svg');
        width: 4rem;
        height: 4rem;
      }

      & > .DayPicker-NavButton--next {
        right: 1rem;
        top: 1.8rem;
        background-image: url('../../assets/app/goForwardIcon.svg');
        width: 4.5rem;
        height: 4.5rem;
      }
    }
    & > .DayPicker-Months {
      & > .DayPicker-Month {
        width: 100% !important;
        margin: 0;
        color: $text-color-primary;
        font-family: $font-family;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        & > .DayPicker-Caption {
          text-align: center;
          margin-bottom: 2rem;
        }
        & > .DayPicker-Weekdays {
          & > .DayPicker-WeekdaysRow {
            & .DayPicker-Weekday {
              font-size: 1.4rem;
              color: $text-color-secondary;
            }
          }
        }
        margin-top: 1em;
        & > .DayPicker-Body {
          & > .DayPicker-Week {
            & > .DayPicker-Day {
              padding: 1.2rem;
              font-size: 1.6rem;
              font-weight: $font-weight-base;
              white-space: pre;
              &:hover{
                background-color: rgba(79%, 17%, 20%, 0.1);
              }
            }
           & .DayPicker-Day--withDots {
             position: relative;
             &::after{
               content: '';
               position: absolute;
               width: 5px;
               height: 5px;
               border-radius: 50%;
               background-color: red;
               bottom: 0;
               left: 50%;
               transform: translate(-50%);
             }          
            }
            & > .DayPicker-Day--selected {
              background-color: rgba(79%, 17%, 20%, 0.1) !important;
              color: $text-color-primary;
            }
          }
        }
      }
    }
  }
}
