@import '../../styles/utils/variables';
@import '../../styles/components/button';
@import '../../styles/utils/mixins';

.navbar {
    display: flex;
    max-width: 144rem;
    justify-content: space-between;
    align-items: center;
    padding-left: 17.2rem;
    padding-right: 7.6rem;
    height: 100%;
    width: 100%;
    @include mq(xxl) {
      padding-left: 7rem;
      padding-right: 7rem;
    }
    @include mq(xxxl) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  .logoLink {
    display: flex;
    align-items: center;
    width: 13.2rem;
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
  }
  .nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 4rem 0 1rem;
    @include mq(xxl) {
      margin: 0 1rem 0 2rem;
    }
  }
  .navItem {
    margin-right: 4rem;
    &:last-of-type() {
      margin-right: 0rem;
    }
    @include mq(xxl) {
      margin-right: 3rem;
    }
  }
  .navLink {
    font-size: 1.6rem;
    line-height: 1.75;
    font-weight: $font-weight-bold;
    color: $text-color-primary;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #8e99ac;
    }
    @include mq(xxl) {
      font-size: 1.5rem;
    }
  }
  .rightSightHeader {
    display: flex;
    justify-content: flex-end;
  }
  .navLinkSignUp {
    @extend .navLink;
    font-weight: $font-weight-base;
    margin-right: 2.25rem;
  }
  .buttonWrapper {
    margin: 2rem;
  }
  .buttonPrimary {
    @extend .buttonPrimary;
  }
  .navLinkUserIcon {
    border-radius: 10rem;
    background: $text-color-label;
    text-decoration: none;
    padding: 1.8rem 1.7rem;
    font-size: 1.5rem;
    line-height: 1.65rem;
    color: $text-color-white;
    border: 2px solid $text-color-label;
    cursor: pointer;
    &:hover,
    &:active {
      background-color: $background-color-button-primary-hover;
      border: 2px solid $background-color-button-primary-hover;
    }
  }
  
  .autoCompliteContainer {
    width:100%;
    min-width: 18rem;
    max-width: 27rem;
    border: 1px solid #2B2D40;
    border-radius: 4px;
    @include mq(xxl) {
      max-width: 20rem;
    }
  }
  