.errorBoundary {
  background-color: #ebf8f7;
  padding-left: 3rem;
  padding-top: 2rem;
  height: calc(100vh - 23.7rem);
}

.heading {
  font-size: 2.5rem;
  
}
