@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.footer {
  background-color: $background-color-primary;
  display: flex;
  align-items: center;
  height: 15.7rem;
  min-height: 15.7rem !important;
  width: 100%;
  position: relative;
  z-index: 10;
  border-top: 1px solid #ceeeeb;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11.2rem 0 6.1rem;
  width: 100%;
  height: 100%;
}
.leftSide,
.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftSide {
  flex: 1;
}
.rightSide {
  justify-content: space-between;
  padding-bottom: 1rem;
}
.nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 11rem;
}
.navItem {
  margin-right: 4.2rem;
  &:nth-of-type(2) {
    margin-right: 5.3rem;
  }
  &:last-of-type() {
    margin-right: 0rem;
  }
}
.navLink {
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: $font-weight-base;
  color: $text-color-description;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    color: $text-color-primary;
  }
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10rem;
  padding-top: 0.5rem;
}

.logoLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
.copyright {
  font-size: 1rem;
  font-weight: $font-weight-base;
  line-height: 1.6;
  color: $text-color-secondary;
  margin-top: 3.2rem;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.infoNavLink {
  @extend .navLink;
  @extend .copyright;
  margin-top: 0.8rem;
}
.contactUs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsLink {
  @extend .navLink;
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 0.5rem;
  color: $text-color-primary;
  &:hover {
    color: $text-color-description;
  }
}
.grass {
  position: absolute;
  z-index: 101;
  right: 4.5rem;
  top: 11rem;
}
