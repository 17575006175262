@import '../../styles/utils/variables';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.9rem;
  min-height: 8.9rem;
  background: $background-color-primary;
  width: 100%;
  position: relative;
  top:0;
  left:0;
  z-index: 3;
}
